import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import { useLoaderData } from "react-router-dom";

function renderCase(testCase) {
  const content = JSON.parse(testCase.content);
  const origin = JSON.parse(testCase.origin);
  const explanation = JSON.parse(testCase.explanation);

  const headers = Object.keys(content);

  // The content table format is:
  // {header1: {idx0: value, idx1: value, ...}, header2: {idx0: value, idx1: value, ...}, ...}
  // We turn it into a format for AgGrid:
  // [{header1: value, header2: value, ...}, {header1: value, header2: value, ...}, ...]
  const rows = [];
  const contentValues = Object.values(content);

  if (contentValues.length > 0) {
    const numRows = Object.keys(contentValues[0]).length;
    for (let i = 0; i < numRows; i++) {
      const row = {};
      for (const header of headers) {
        row[header] = content[header][i];
      }
      rows.push(row);
    }
  }

  const colDefs = Object.keys(content).map((header) => {
    return { field: header, flex: 1 };
  });

  return (
    <div key={testCase.id} className="p-4">
      <h1 className="text-left text-xl">{testCase.id}</h1>
      <div
        className="ag-theme-quartz" // applying the grid theme
        style={{ height: 500 }} // the grid will fill the size of the parent container
      >
        <AgGridReact rowData={rows} columnDefs={colDefs} />
      </div>
    </div>
  );
}

export function ManualTestResults() {
  const cases = useLoaderData();

  return (
    <div>
      <h1>ManualTest</h1>
      {cases?.map((testCase) => renderCase(testCase))}
    </div>
  );
}
