// A library for fetching data from the server, at localhost:8000

import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  getDoc,
  doc,
} from "firebase/firestore";

import { db } from "./FirebaseConfig";

export async function fetchColumnFillManualTests() {
  const columnFillManualTestsRef = collection(db, "column_fill_manual_tests");
  const q = query(
    columnFillManualTestsRef,
    orderBy("created_at", "desc"),
    limit(10)
  );
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return data;
}

export async function fetchTableFillManualTests() {
  const tableFillManualTestsRef = collection(db, "table_fill_manual_tests");
  const q = query(
    tableFillManualTestsRef,
    orderBy("created_at", "desc"),
    limit(10)
  );
  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return data;
}

export async function fetchColumnFillManualTestCases(id) {
  const querySnapshot = await getDocs(
    collection(db, `column_fill_manual_tests/${id}/cases`)
  );
  const data = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return data;
}

export async function fetchTableFillManualTestCases(id) {
  const querySnapshot = await getDocs(
    collection(db, `table_fill_manual_tests/${id}/cases`)
  );
  const data = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return data;
}

export async function fetchBenchmarkRuns() {
  const querySnapshot = await getDocs(collection(db, "benchmark_runs"));
  const data = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return data;
}

export async function fetchBenchmarkResults(id) {
  // This is kind of a hassle, but if we don't add a doc
  // for the benchmark name we won't be able to retrieve it.
  const benchmarks = ["london", "paris", "boise"];

  // Loop through each benchmark asynchronously.
  const results = await Promise.all(
    benchmarks.map(async (benchmark) => {
      const runVariants = await getDocs(
        collection(
          db,
          "benchmark_runs",
          id,
          "benchmarks",
          benchmark,
          "run_variants"
        )
      );

      return runVariants.docs.map((doc) => {
        return {
          variant: doc.id,
          ...doc.data(),
        };
      });
    })
  );

  // Join the result with the benchmark name.
  const resultByBenchmarkName = {};
  for (let i = 0; i < benchmarks.length; i++) {
    resultByBenchmarkName[benchmarks[i]] = results[i];
  }

  return resultByBenchmarkName;
}
