import { useContext } from "react";

import { useLoaderData, useLocation, Link, Outlet } from "react-router-dom";

import "./App.css";
import { AuthContext } from "./AuthProvider";
import { SidebarPage } from "./Components";

function renderColumnFillTest(test) {
  return (
    <div key={test.id} className="flex justify-between items-center py-2">
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap w-1/2">
          <div>
            <Link to={`${test.id}`}>{test.name}</Link>
          </div>
          <div className="text-left text-gray-500 text-xs">
            {test.created_at.toDate().toLocaleString()}
          </div>
        </div>
        <div className="flex flex-wrap justify-end">
          {test.tags.map((tag, tagIndex) => (
            <div
              key={tagIndex}
              className="text-white rounded-full px-2 py-1 text-xs mr-2 mb-2 bg-blue-700"
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function LoadingPage() {
  return <div>Loading...</div>;
}

function LoggedOutPage() {
  return <div>Signed out</div>;
}

export function ManualTestPage({ title }) {
  const columnFillManualTests = useLoaderData();

  return (
    <SidebarPage>
      <h1 className="text-xl font-bold mb-4">{title}</h1>
      {columnFillManualTests.map((test) => renderColumnFillTest(test))}
    </SidebarPage>
  );
}

export function App() {
  const { user, loading, logOut } = useContext(AuthContext);

  let inner = <LoadingPage />;
  if (user) {
    inner = <Outlet />;
  } else if (!loading) {
    inner = <LoggedOutPage />;
  }

  const location = useLocation();

  // Get the location parts, removing the first empty string."
  const locationParts = location.pathname.split("/").splice(1);

  return (
    <>
      <header>
        <nav className="flex justify-between items-center">
          <span className="space-x-6 ml-4">
            <span>
              <Link to="/">Home</Link>
            </span>
            <span>
              <Link to="/column_fill_manual_test">
                Column Fill Manual Tests
              </Link>
            </span>
            <span>
              <Link to="/table_fill_manual_test">Table Fill Manual Tests</Link>
            </span>
            <span>
              <Link to="/benchmarks">Benchmarks</Link>
            </span>
          </span>
          <span className="ml-auto mr-4" onClick={logOut}>
            Logout
          </span>
        </nav>
      </header>
      {inner}
    </>
  );
}
