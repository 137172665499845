import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useLoaderData, Link } from "react-router-dom";

import { SidebarPage } from "./Components";

function renderRun(run) {
  // Run has:
  // - id: string
  // - name: string
  // - created_at: Timestamp

  return (
    <div key={run.id} className="flex justify-between items-center py-2">
      <div className="flex items-center justify-between">
        <Link to={run.id}>
          <div className="flex flex-wrap w-1/2">
            <div>{run.name}</div>
            <div className="text-left text-gray-500 text-xs">
              {run.created_at.toDate().toLocaleString()}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export function BenchmarksPage() {
  const runs = useLoaderData();

  return (
    <SidebarPage>
      <h1 className="text-xl font-bold mb-4">Benchmarks</h1>
      {runs.map((run) => renderRun(run))}
    </SidebarPage>
  );
}

export function BenchmarkResultPage() {
  const resultsByBenchmarkName = useLoaderData();

  // Loop through key and value of results.
  let tables = [];

  for (const [benchmarkName, benchmarkResults] of Object.entries(
    resultsByBenchmarkName
  )) {
    const rows = [];

    for (const result of benchmarkResults) {
      console.log(result.variant, result);
      rows.push(result);
    }

    const colDefs = [
      { field: "variant" },
      { field: "score" },
      { field: "soft_score" },
    ];

    tables.push(
      <div key={benchmarkName} className="p-4">
        <h1 className="text-left text-xl">{benchmarkName}</h1>

        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500 }} // the grid will fill the size of the parent container
        >
          <AgGridReact rowData={rows} columnDefs={colDefs} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>Benchmark Results</h1>
      {tables}
    </div>
  );
}
