// FirebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCThzRwaW6hCt02HkDmCqBmaQvWS0R5a70",
  authDomain: "pharma-helper.firebaseapp.com",
  projectId: "pharma-helper",
  storageBucket: "pharma-helper.appspot.com",
  messagingSenderId: "791691786571",
  appId: "1:791691786571:web:09913bf9e4f8980f6c90ce",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app, "ml-results");
