import React from "react";
import ReactDOM from "react-dom/client";

import {
  createBrowserRouter,
  useLocation,
  Link,
  Outlet,
  RouterProvider,
} from "react-router-dom";

import "./index.css";
import { App, ManualTestPage } from "./App";
import { ManualTestResults } from "./ManualTest";
import reportWebVitals from "./reportWebVitals";

import AuthProvider from "./AuthProvider";
import { BenchmarksPage, BenchmarkResultPage } from "./Benchmarks";
import {
  fetchBenchmarkResults,
  fetchBenchmarkRuns,
  fetchColumnFillManualTests,
  fetchColumnFillManualTestCases,
  fetchTableFillManualTests,
  fetchTableFillManualTestCases,
} from "./fetch";

const router = createBrowserRouter([
  {
    element: <App />,
    path: "/",
    children: [
      {
        path: "column_fill_manual_test",
        element: <ManualTestPage title="Column Fill Manual Tests" />,
        loader: async () => {
          return await fetchColumnFillManualTests();
        },
        children: [
          {
            path: ":id",
            element: <ManualTestResults />,
            loader: async ({ params }) => {
              return await fetchColumnFillManualTestCases(params.id);
            },
          },
        ],
      },
      {
        path: "table_fill_manual_test",
        element: <ManualTestPage title="Table Fill Manual Tests" />,
        loader: async () => {
          return await fetchTableFillManualTests();
        },
        children: [
          {
            path: ":id",
            element: <ManualTestResults />,
            loader: async ({ params }) => {
              return await fetchTableFillManualTestCases(params.id);
            },
          },
        ],
      },
      {
        path: "benchmarks",
        element: <BenchmarksPage />,
        loader: async () => {
          return await fetchBenchmarkRuns();
        },
        children: [
          {
            path: ":id",
            element: <BenchmarkResultPage />,
            loader: async ({ params }) => {
              return await fetchBenchmarkResults(params.id);
            },
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
