import { Outlet } from "react-router-dom";

export function SidebarPage({ children }) {
  return (
    <div className="App h-screen w-screen flex flex-wrap">
      <div className="sidebar w-1/4 bg-gray-200 p-4 h-full fixed">
        {children}
      </div>
      <div className="w-3/4 ml-[25%]">
        <Outlet />
      </div>
    </div>
  );
}
